<template>
  <div class="details">
    <!-- 轮播图上的标签 -->
    <van-nav-bar :border="false">
      <template #left>
        <van-icon name="arrow-left" @click="onBak"></van-icon>
      </template>
      <template #title>
        <div :style="'width:' + (screenWidth - 110) + 'px;'">
          <van-icon v-if="false" name="share" @click="onShare"></van-icon>
          <van-icon
            v-if="merchid == 0 || showkefu == 1"
            :name="userinfo.favorite == 1 ? 'star' : 'star-o'"
            :class="userinfo.favorite == 1 ? 'favorited' : ''"
            @click="Favorite"
          ></van-icon>
        </div>
      </template>
      <template #right>
        <van-icon name="qr" @click="onQr"></van-icon>
      </template>
    </van-nav-bar>
    <!-- 轮播图 -->
    <div class="thumbs" :style="'width:100%;height:' + screenWidth + 'px;'">
      <van-swipe
        class="my-swipe"
        :autoplay="3000"
        indicator-color="white"
        v-if="info.thumb_url"
      >
        <van-swipe-item v-for="(item, index) in info.thumb_url" :key="index">
          <van-image
            style="width: 100%; height: 100%"
            fit="cover"
            :src="item"
            lazy-load
          >
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
        </van-swipe-item>
      </van-swipe>
      <div class="qiukucun" @click="qiukucun">求库存</div>
    </div>
    <!-- 价格 -->
    <div class="price">
      <van-cell>
        <template #icon v-if="info.erp_sync == 1 ? true : false">
          <van-tag round type="danger">自营</van-tag>
        </template>
        <template #title>
          <div>
            <span>&yen;</span>
            <strong>{{ info.show_price }}</strong>
            <strong
              v-if="
                parseFloat(info.maxprice) &&
                parseFloat(info.maxprice) > parseFloat(info.show_price)
              "
            >~</strong>
            <span
              v-if="
                parseFloat(info.maxprice) &&
                parseFloat(info.maxprice) > parseFloat(info.show_price)
              "
              >&yen;</span
            >
            <strong
              v-if="
                parseFloat(info.maxprice) &&
                parseFloat(info.maxprice) > parseFloat(info.show_price)
              "
              >{{ info.maxprice }}</strong
            >
            <span
              style="color: #999"
              v-if="parseInt(info.salesreal) > 0 && info.showsales == 1"
            >
              已售{{ info.salesreal }}件
            </span>
          </div>
        </template>
        <template #right-icon v-if="info.THstock && parseInt(info.THstock) > 0">
          <van-tag plain type="success" class="tunhuo"
            >囤货，剩余{{ info.THstock }}件</van-tag
          >
        </template>
      </van-cell>
    </div>
    <!-- 属性标签 -->
    <div
      class="attrtag"
      v-show="
        info.iscomment == '1' ||
        info.isnew == '1' ||
        info.ishot == '1' ||
        info.issendfree == '1'
          ? true
          : false
      "
    >
      <van-tag v-show="info.iscomment == '1' ? true : false">首页置顶</van-tag>
      <van-tag v-show="info.isnew == '1' ? true : false">今日主推</van-tag>
      <van-tag v-show="info.ishot == '1' ? true : false">明日预告</van-tag>
      <van-tag v-show="info.issendfree == '1' ? true : false">包邮</van-tag>
    </div>
    <!-- 标题 -->
    <div class="title">
      <van-cell :border="false">
        <template #title>{{ info.title }}{{ strId }}</template>
        <template #label>
          <div v-show="info.subtitle ? true : false">{{ info.subtitle }}</div>
        </template>
      </van-cell>
    </div>
    <!-- 会员、价格 -->
    <div class="goods_part">
      <van-cell-group :border="false" class="huiyuan">
        <van-cell :border="false" title="会员" v-if="userinfo.id && islogin && info['commission_list'] && info['commission_list'].length > 0 ? true : false">
          <template #default>
            <!-- <van-tag
              plain
              type="danger"
              v-if="parseFloat(info.commission1_pay) > 0"
            >
              {{parseFloat(info.commission1_pay_putong) > 0 ? "邀请特级会员购买得佣金:" : "邀请购买得佣金:"}}
              {{ parseFloat(info.commission1_pay) }}元
            </van-tag>
            <van-tag
              plain
              type="danger"
              v-if="parseFloat(info.commission1_pay_putong) > 0"
            >
              邀请普通会员购买得佣金:{{ parseFloat(info.commission1_pay_putong) }}元
            </van-tag> -->
            <van-tag plain type="danger" v-for="(item,index) in info['commission_list']" :key="index">
              {{item.name}}：{{ item.price }}
            </van-tag>
          </template>
        </van-cell>
        <van-cell
          :border="false"
          title="价格"
          class="show_price"
          v-if="userinfo.id &&
          userinfo.level_level &&
          userinfo.level_level > 1 &&
          info.discounts_show &&
          info.discounts_show[0].minprice &&
          islogin"
        >
          <template #default>
            <van-tag
              plain
              type="danger"
              v-for="item in info.discounts_show"
              :key="item.levelid"
            >
              <span v-if="item.minprice || item.maxprice"
                >{{ item.levelname }}:{{
                  parseFloat(item.minprice) == parseFloat(item.maxprice)
                    ? parseFloat(item.minprice)
                    : parseFloat(item.minprice) +
                      "~" +
                      parseFloat(item.maxprice)
                }}元</span
              >
            </van-tag>
          </template>
        </van-cell>
        <van-cell
          :border="false"
          title="积分"
          class="show_price"
          v-if="info.credit_arr && info.credit_arr.length > 0"
        >
          <template #default>
            <van-tag
              plain
              type="danger"
              v-for="item in info.credit_arr"
              :key="item.level"
            >
              <span v-if="item.credit"
                >{{ item.levelname }}:{{
                  "送" + parseFloat(item.credit) + "积分"
                }}</span
              >
            </van-tag>
          </template>
        </van-cell>
        <van-cell
          :border="false"
          title="满减"
          class="show_price"
          v-if="
            (info.enoughnumsdeduct &&
              parseFloat(info.enoughnumsdeduct) > 0 &&
              info.enoughnums &&
              parseFloat(info.enoughnums) > 0) ||
            (parseFloat(info.enoughpricesdeduct) > 0 &&
              parseFloat(info.enoughprices) > 0)
          "
        >
          <template #default>
            <div
              class="red"
              v-if="info.enoughnums && parseFloat(info.enoughnums) > 0"
            >
              满{{ parseInt(info.enoughnums) }}件减{{
                parseFloat(info.enoughnumsdeduct)
              }}元
            </div>
            <div
              class="red"
              v-if="
                info.enoughpricesdeduct &&
                parseFloat(info.enoughpricesdeduct) > 0
              "
            >
              满{{ parseFloat(info.enoughprices) }}元减{{
                parseFloat(info.enoughpricesdeduct)
              }}元
            </div>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <!-- 微商相册关键词 -->
    <div class="weishangxiangce" v-if="info.keywords">
      <van-cell :border="false" title-class="guanjianci">
        <template #title> 微商相册搜索关键词 </template>
        <template #default>
          <input type="text" v-model="info.keywords" v-show="false" />
          <van-tag
            type="danger"
            plain
            round
            v-clipboard:copy="info.keywords"
            v-clipboard:success="keywordsonCopy"
            v-clipboard:error="keywordsonError"
            >复制文字</van-tag
          >
        </template>
      </van-cell>
      <van-cell :border="false" title>
        <template #label> # {{ info.keywords }} # </template>
      </van-cell>
    </div>
    <!-- 发货、特殊区域 -->
    <div
      class="goods_part"
      v-if="
        info.tip_desc || info.psotage_title || info.edareas || info.nobaoyou
      "
    >
      <van-cell-group :border="false" class="postage">
        <van-cell
          :border="false"
          title="发货"
          v-if="info.tip_desc || info.psotage_title"
        >
          <template #default>
            <van-tag plain class="address" v-if="info.tip_desc">
              <van-icon name="location-o"></van-icon>
              {{ info.tip_desc }}
            </van-tag>
            <van-tag plain class="express" v-if="info.tip_time">
              发货时间：{{ info.tip_time }}
            </van-tag>
          </template>
        </van-cell>
        <van-cell
          :border="false"
          title="特殊区域"
          v-if="
            info.edareas ||
            info.nobaoyou ||
            (info.postage && info.postage.nodispatchareas)
              ? true
              : false
          "
          class="teshuquyu"
          @click="showTeshuArea"
        >
          <template #default>
            <div class="van-ellipsis">
              {{ info.edareas ? info.edareas : "" }}
              {{ info.nobaoyou ? info.nobaoyou : "" }}
              <span v-if="info.postage && info.postage.nodispatchareas">
                {{
                  info.postage && info.postage.nodispatchareas
                    ? info.postage.nodispatchareas
                    : ""
                }}
              </span>
            </div>
          </template>
          <template #right-icon>
            <van-icon name="arrow"></van-icon>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <!-- 规格参数 -->
    <div class="goods_part">
      <van-cell-group :border="false" class="spec">
        <van-cell :border="false" title="选择" is-link @click="gobuying">
          <template #default>
            请选择{{ skuTitle ? skuTitle : "规格" }}
          </template>
        </van-cell>
        <van-cell
          :border="false"
          @click="showParam"
          title="参数"
          class="param"
          is-link
          v-if="param && param.length > 0"
        >
          <template #default>
            <div class="van-ellipsis">
              <span
                style="margin-right: 5px"
                v-for="item in param"
                :key="item.id"
              >
                {{ item.title }}
              </span>
            </div>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <!-- 进店逛逛 -->
    <div class="ghsinfo" style="margin-top: 10px;"  v-if="ghsinfo.is_show == 1">
      <van-cell center :border="false">
        <template #icon>
          <van-image
            :src="ghsinfo.logo"
            lazy-load
            style="width: 55px; height: 55px; display: block;border-radius: 4px;"
          >
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
        </template>
        <template #title>
          {{ghsinfo.merchname}}
        </template>
        <template #label>
          销量{{ghsinfo.num_salse}}  共{{ghsinfo.num_goods}}款商品
        </template>
        <template #right-icon>
          <van-button round plain @click="goPageMerchDetail(ghsinfo.id)">进店逛逛</van-button>
        </template>
      </van-cell>
    </div>
    
    <!-- 产品详情 -->
    <div class="goods_content">
      <van-cell
        title="产品详情"
        v-if="info.thumb_first == 0 && !content ? false : true"
        :border="false"
      ></van-cell>
      <van-list
        v-model="listloading"
        :finished="finished"
        finished-text="到底啦"
        :immediate-check="false"
        @load="loadDetail"
        :offset="0"
      >
        <div class="goods_content_text">
          <van-image
            v-if="info.thumb_first == 1 ? true : false"
            :src="info.thumb"
            lazy-load
          >
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
          <div id="content" v-html="content">{{ content }}</div>
        </div>
      </van-list>
    </div>
    <!-- 底部按钮 -->
    <van-goods-action>
      <van-goods-action-icon icon="shop-o" text="首页" @click="gohome" />
      <van-goods-action-icon
        v-if="merchid == 0 || showkefu == 1 ? false : true"
        :class="userinfo.favorite == 1 ? 'favorited' : ''"
        :icon="userinfo.favorite == 1 ? 'star' : 'star-o'"
        text="收藏"
        @click="Favorite"
      />
      <van-goods-action-icon
        v-if="merchid == 0 || showkefu == 1"
        icon="service-o"
        text="客服"
        @click="qiyukefu"
      />
      <van-goods-action-icon
        @click="onCart"
        icon="shopping-cart-o"
        text="购物车"
        :badge="cartNum > 0 && islogin ? cartNum : ''"
      />
      <van-goods-action-button
        type="warning"
        text="加入购物车"
        :disabled="AddcartAble"
        @click="gobuying"
      />
      <van-goods-action-button
        type="danger"
        :text="buybtntext"
        :disabled="buybtnable"
        @click="gobuying"
      />
    </van-goods-action>
    <!-- 产品sku -->
    <van-sku
      v-model="showBase"
      :sku="skuData.sku"
      :goods="skuData.goods"
      :goods-id="skuData.goodsid"
      :hide-stock="skuData.sku.hidestock"
      :quota="skuData.quota"
      :quota-used="skuData.quotaused"
      :initial-sku="skuData.initialSku"
      :price-tag="yushouTime"
      reset-stepper-on-hide
      reset-selected-sku-on-hide
      disable-stepper-input
      :close-on-click-overlay="closeOnClickOverlay"
      :custom-sku-validator="customSkuValidator"
      @buy-clicked="onBuyClicked"
      @add-cart="onAddCartClicked"
      :show-add-cart-btn="!AddcartAble"
      @sku-selected="skucheck"
      lazy-load
      ref="vanskudata"
    >
      <template #sku-header-image-extra>
        <span class="shuiyin">{{ strId }}</span>
      </template>
      <template #sku-actions="props">
        <div class="van-sku-actions">
          <van-button square size="large" type="warning" @click="props.skuEventBus.$emit('sku:addCart')">
            加入购物车
          </van-button>
          <van-button
            square
            size="large"
            type="danger"
            @click="moreAddresspay"
          >
            多地址下单
          </van-button>
          <!-- 直接触发 sku 内部事件，通过内部事件执行 onBuyClicked 回调 -->
          <van-button
            square
            size="large"
            type="danger"
            @click="props.skuEventBus.$emit('sku:buy')"
          >
            立即购买
          </van-button>
        </div>
      </template>
    </van-sku>

    <!-- 特殊区域弹框 -->
    <van-popup
      v-model="teshushow"
      closeable
      close-icon="close"
      position="bottom"
      class="popup_teshuarea"
      :style="{ height: '60%' }"
    >
      <van-cell :border="false" class="area_title" title="特殊区域" />
      <van-cell-group :style="'height:' + (screeHeight * 0.6 - 96) + 'px;'">
        <van-cell-group
          :border="false"
          v-if="info.postage && info.postage.areas.length > 0"
        >
          <van-cell
            :border="false"
            class="area_list_title"
            title="不包邮地区"
          />
          <van-cell
            :border="false"
            v-for="(item, index) in info.postage.areas"
            :key="index"
            class="area_list_content"
          >
            <template #title>
              {{ item.calculatetype == "0" ? "首重" : "首件" }}：
              {{
                item.firsprice
                  ? item.firsprice + "元"
                  : item.firstnumprice + "元"
              }}
            </template>
            <template #default>{{ item.citys }}</template>
          </van-cell>
        </van-cell-group>
        <van-cell-group
          :border="false"
          v-if="
            info.edareas ||
            (info.postage &&
              info.postage.isdispatcharea == '0' &&
              info.postage.nodispatchareas)
          "
        >
          <van-cell
            :border="false"
            class="area_list_title"
            title="不配送区域"
          />
          <van-cell :border="false" class="area_list_content">
            <template #default>
              <span v-if="info.edareas">{{ info.edareas }}</span>
              <span
                v-if="
                  info.postage &&
                  info.postage.isdispatcharea == '0' &&
                  info.postage.nodispatchareas
                "
              >
                {{
                  info.postage && info.postage.nodispatchareas
                    ? info.postage.nodispatchareas
                    : ""
                }}
              </span>
            </template>
          </van-cell>
        </van-cell-group>
        <van-cell-group
          :border="false"
          v-if="
            info.postage &&
            info.postage.isdispatcharea == '1' &&
            info.postage.nodispatchareas
          "
        >
          <van-cell
            :border="false"
            class="area_list_title"
            title="只配送区域"
          />
          <van-cell :border="false" class="area_list_content">
            <template #default>
              {{
                info.postage && info.postage.nodispatchareas
                  ? info.postage.nodispatchareas
                  : ""
              }}
            </template>
          </van-cell>
        </van-cell-group>
      </van-cell-group>

      <van-button :border="false" @click="showTeshuArea" class="popup_button">
        完成
      </van-button>
    </van-popup>

    <!-- 参数弹框 -->
    <van-popup
      v-model="paramShow"
      closeable
      close-icon="close"
      position="bottom"
      class="popup_param"
      :style="{ height: '60%' }"
    >
      <van-cell class="param_title" :border="false" title="产品参数"></van-cell>
      <van-cell-group
        :border="false"
        v-if="param && param.length > 0"
        :style="'height:' + (screeHeight * 0.6 - 96) + 'px;'"
      >
        <van-cell
          title-class="list_title"
          value-class="list_value"
          v-for="(item, index) in param"
          :key="index"
          :title="item.title"
          :value="item.value"
        />
      </van-cell-group>
      <van-button :border="false" @click="showParam" class="param_button">
        完成
      </van-button>
    </van-popup>
    <!-- 加载中提示 -->
    <div id="loading" v-show="!loadshow">
      <van-loading size="24px" type="spinner" vertical>加载中...</van-loading>
    </div>
    <!-- 商品海报弹框 -->
    <van-popup
      class="haibao_main"
      v-model="showHaibao"
      closeable
      close-icon="close"
    >
      <div
        class="haibaoshare"
        id="haibaoshare"
        :style="
          'height: ' +
          (screenWidth * 0.8 + 160) +
          'px;overflow: hidden;'
        ">
        <van-loading size="24px" vertical :style="'margin-top:' + (screenWidth * 0.8 + 100)/2 + 'px;'">加载中...</van-loading>
      </div>
      <div
        id="show"
        :style="
          'height: ' +
          (screenWidth * 0.8 + 190) +
          'px;overflow: hidden;display: none;'
        "
      >
        <img
          id="img"
          crossorigin="anonymous"
          style="
            width: 100%;
            height: 100%;
            margin: 0 auto;
            padding: 0;
            display: block;
          "
        />
      </div>
    </van-popup>
    <!-- 输入邀请码弹框 -->
    <van-popup
      v-model="showAddAgentCode"
      round
      :close-on-click-overlay="false"
      style="width: 80%;"
    >
      <div class="agent_code">
        <van-field
          v-model="agentCode"
          placeholder="请输入邀请码"
          input-align="center"
          :border="false"
        />
        <van-button
          block
          round
          color="linear-gradient(to right, rgb(255, 96, 52), rgb(238, 10, 36))"
          @click="onAgentCodeConfirm"
          :loading="addAgentBtnLoding"
        >
          保存
        </van-button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import skuData from "@/components/data.js";
export default {
  name: "Details",
  data() {
    return {
      // 初始数据
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      param: [], // 参数信息
      info: {}, // 产品信息
      ghsinfo: {},
      skuData: skuData,
      skuTitle: "", // sku标题
      teshushow: false, // 默认特殊区域弹框隐藏
      paramShow: false, // 参数弹框默认隐藏
      showBase: false,
      showCustom: false,
      showStepper: false,
      showSoldout: false,
      closeOnClickOverlay: true,
      goodsId: 0,
      loadshow: true, // 加载中显示
      islogin: localStorage.getItem("DK_ISLOGIN")
        ? localStorage.getItem("DK_ISLOGIN")
        : false, // 默认不登录
      userinfo: {}, // 用户信息
      merchinfo: {}, // 商户信息
      UID: "", // 用户信息
      cartNum: 0, // 购物车产品数量
      showHaibao: false, // 是否显示商品海报
      tempSrc: "", // 海报图片
      shareid: 0, // 分享者用户id
      wxConfig: {
        debug: false, // 开启调试模式 true false
        appId: "wxc465682b705715e5",
        jsApiList: [
          "updateAppMessageShareData",
          "updateTimelineShareData",
          "onMenuShareTimeline",
          "onMenuShareAppMessage",
        ], // 必填，需要使用的JS接口列表
        timestamp: 1585098244, // 必填，生成签名的时间戳
        nonceStr: "dfbfwkm1egc", // 必填，生成签名的随机串
        signature: "d8caa8a88d588a8dc5379dbfbde5d875886baed5", // 必填，签名
      },
      iswx: false, // 是否在微信中
      frompage: "", // 上一个页面的 路由
      merchid: 0,
      code: null, // 页面中的code值
      listloading: false, // list是否加载中
      finished: false, // list 是否加载完成
      content: "", // 产品详情
      strId: "", // 水印
      buybtntext: "立即购买", // 立即购买按钮默认文字
      buybtnable: true, // 立即购买按钮默认不能用
      AddcartAble: true, // 加入购物车按钮默认不能用
      getUnionid: false, // 是否需要获取微信unionid
      showkefu: 0,
      yushouTime: "",
      showAddAgentCode: false,
      agentCode: '',
      shareagentId: 0,
      addAgentBtnLoding: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      //  这里的vm指的就是vue实例，可以用来当做this使用
      vm.frompage = from.path;
    });
  },
  mounted() {
    if (window.isWeixin()) {
      this.iswx = true;
    }
    this.shareagentId = this.$route.query.shareid ? this.$route.query.shareid : 0;
    
    console.log(localStorage.getItem("DK_ISLOGIN"), "localStorage.getItem(\"DK_ISLOGIN\")");
    if (localStorage.getItem("DK_ISLOGIN") == 'true') {
      this.getShowAddAgentcode();
    }
    
    this.strId = localStorage.getItem("DK_STRID");
    this.showkefu = localStorage.getItem("DK_SHOWKEFU");
    if (!localStorage.getItem("DK_WXINFO") && this.iswx) {
      // 用户授权后获取code值
      this.code = this.$route.query.code ? this.$route.query.code : null;
      if (this.code) {
        this.getwxlog(this.code);
        return false;
      }else if (!localStorage.getItem("DK_WXINFO")) {
        this.getwxinfo(); // 自动获取用户信息
        return false;
      }
    }else{
      if (this.iswx) {
        this.code = this.$route.query.code ? this.$route.query.code : null;
        if (this.code) {
          this.$router.replace({ name: "Home", query: { shareid: this.shareid, merchid: this.merchid, _t: 3 } })
        }
      }
    }
  },
  activated() {
    if (this.$route.query.shareid && this.$route.query.shareid > 0) {
      this.shareid = this.$route.query.shareid;
    } else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;

    this.$parent.getmerchname(this.merchid, "产品详情");

    let _that = null;
    _that = this;
    this.showHaibao = false;
    if (window.isWeixin()) {
      this.iswx = true;
      const openid = localStorage.getItem("DK_OPENID");
      const memberid = localStorage.getItem("DK_MEMBERID");
      if (openid && memberid) {
        _that.updateOpneUser(memberid, openid);
      }
    }
    // 不同商品时 要清空海报图片，以生成新的二维码
    _that.tempSrc = "";
    _that.listloading = false;
    _that.finished = false;
    
    // 产品是否变化 如果未变化先不请求数据
    if (parseInt(this.goodsId) != parseInt(this.$route.query.id)) {
      /* 页面隐藏时显示加载空白页 */
      _that.loadshow = false;
      this.goodsId = this.$route.query.id;
      this.info = {};
      this.init();
    }
    this.islogin = localStorage.getItem("DK_ISLOGIN") ? true : false; // 默认不登录
    /* 获取购物车产品数量 */
    this.getCartNum();
    /* 绑定用户和子商户会员关系 */
    if (this.merchid > 0) {
      this.setmerchMber();
    }

    setTimeout(() => {
      _that.showkefu = localStorage.getItem("DK_SHOWKEFU");
      console.log(_that.$store.state.showkefu, "showkefu");
    }, 500);
    this.setprivew(); // 增加浏览记录
  },
  deactivated() {
    this.showHaibao = false;
  },
  methods: {
    /* 初始化方法 */
    init() {
      // 获取产品信息
      this.getgoodsInfo();
      let wxinfo = null;
      wxinfo = localStorage.getItem("DK_WXINFO");
      let memberid = null;
      memberid = localStorage.getItem("DK_MEMBERID");
      if (window.isWeixin()) {
        if (!wxinfo) {
          this.getunionid();
        } else {
          // this.setUnionId();
        }
      }
    },

    // 查询店铺信息
    getsuofen() {
      let _that = null;
      _that = this;
      let merchid = 0;
      merchid = this.$route.query.merchid;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/user/get_shop_info",
          _that.$qs.stringify({
            id: merchid
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            if (merchid != res.data.data.id) {
              _that.$router.replace({
                name: "Details",
                query: {
                  id: _that.$route.query.id,
                  shareid: _that.$route.query.shareid,
                  merchid: res.data.data.id,
                  _t: 3
                }
              })
              window.location.reload();
            }
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 获取是否需要填写邀请码
    getShowAddAgentcode() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(_that.$store.state.domain + "web/user/get_noShare_uid")
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            if (res.data.data.memberList.indexOf(_that.shareagentId) >= 0 && res.data.data.lock_shop < 0) {
              _that.showAddAgentCode = true;
            }else{
              _that.getsuofen();
            }
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 填写邀请码后点击保存按钮
    onAgentCodeConfirm() {
      let _that = null;
      _that = this;
      if (!_that.agentCode || _that.agentCode.length <= 0) {
        _that.$toast("请填写邀请码");
        return false;
      }
      _that.addAgentBtnLoding = true;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/user/check_agent_code",
          _that.$qs.stringify({
            agentcode: _that.agentCode
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.bindAgentCode();
          }else {
            _that.$toast("请填写正确的邀请码");
            _that.addAgentBtnLoding = false;
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 绑定邀请码
    bindAgentCode() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/user/bind_agent_code",
          _that.$qs.stringify({
            agentcode: _that.agentCode
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.showAddAgentCode = false;
            _that.getsuofen();
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 选择SKU的方法
    skucheck(res) {
      console.log(res, this.$refs.vanskudata);
      if (res.selectedSkuComb && res.selectedSkuComb.yushou_time) {
        // this.buybtntext = "预售购买";
        this.yushouTime = res.selectedSkuComb.yushou_time;
      } else {
        // this.buybtntext = "立即购买";
        this.yushouTime = "";
      }
    },
    // 获取用户是否获取微信unionid
    getunionid() {
      let _that = null;
      _that = this;
      let openid = null;
      openid = localStorage.getItem("DK_OPENID");
      let memberid = null;
      memberid = localStorage.getItem("DK_MEMBERID");
      _that.$axios.defaults.headers["Content-Type"] =
        "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/user/get_unionid",
          _that.$qs.stringify({
            openid: openid,
            mid: memberid,
          })
        )
        .then((res) => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.getUnionid = true; // 需要获取用户unionid
            // localStorage.removeItem("DK_OPENID");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // 保存微信unionid
    setUnionId() {
      let _that = null;
      _that = this;
      let openid = null;
      openid = localStorage.getItem("DK_OPENID");
      let memberid = null;
      memberid = localStorage.getItem("DK_MEMBERID");
      let wxinfo = null;
      wxinfo = localStorage.getItem("DK_WXINFO");
      _that.$axios.defaults.headers["Content-Type"] =
        "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/user/set_unionid",
          _that.$qs.stringify({
            openid: openid,
            mid: memberid,
            wxinfo: wxinfo,
          })
        )
        .then((res) => {
          console.log(res);
          if (res.data.code != 100000) {
            _that.getUnionid = true; // 需要获取用户unionid
            // localStorage.removeItem("DK_OPENID");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取用户微信信息
    getwxlog(code) {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =
        "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/wetch/wx_oauth2",
          _that.$qs.stringify({
            code: code
          })
        )
        .then(function (response) {
          if (response.data) {
            localStorage.setItem("DK_OPENID", response.data); // 获得用户的openid
            _that.updatwxinfo(response.data);
            
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    /* 如果用户登录中未获的用户openID 保存用户openID */
    saveOpenid() {
      let _that = null;
      _that = this;
      let openid = "";
      openid = localStorage.getItem("DK_OPENID");
      if (openid) {
        //查询openID是否保存
        _that.$axios.defaults.headers["Content-Type"] =
          "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/user/getmidByOpenid", // 在user_open表中获取用户id
            _that.$qs.stringify({
              openid: openid,
            })
          )
          .then((res) => {
            if (res.data.code == 100000) {
              // 能查到用户id
              localStorage.setItem("DK_MEMBERID", res.data.data);
              // 查询会员信息
              _that.$axios
                .post(
                  _that.$store.state.domain + "web/user/get_info_byMid",
                  _that.$qs.stringify({
                    id: res.data.data,
                  })
                )
                .then((response) => {
                  if (response.data.code == 100000) {
                    // 能获取用户信息
                    localStorage.setItem("DK_UID", response.data.data);
                    localStorage.setItem("DK_ISLOGIN", true);
                    localStorage.setItem("DK_STRID", response.data.user.strId);
                    localStorage.setItem("DK_MEMBERID", response.data.user.id);
                    localStorage.setItem("DK_TOKEN", response.data.user.pwd);
                    _that.shareid = response.data.user.id;
                    _that.updateOpneUser(response.data.user.id, openid);
                    _that.reloapage(); // 重新加载页面去除code参数 防止刷新报错
                  } else {
                    _that.reloapage(); // 重新加载页面去除code参数 防止刷新报错
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              // user_open表不能查询到会员id
              // _that.reloapage(); // 重新加载页面去除code参数 防止刷新报错
              _that.getMidByMember();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    // 判断是否是新用户
    getMidByMember() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/user/get_mid",
          _that.$qs.stringify({
            openid: localStorage.getItem("DK_OPENID"),
            unionid: localStorage.getItem("DK_UNIONID")
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            // 已经有账号，请登录
            _that.$toast("请登录");
            _that.lijidenglu(); // 跳转登陆页面
          }else if(res.data.code == 100001) {
            // 未有账号，创建账号，并且建立上下级关系
            _that.createAccount();
          }else {
            _that.reloapage(); // 重新加载页面去除code参数 防止刷新报错
          }
        })
        .catch(err => {
          console.log(err);
        })
    },

    // 创建账号，并且保存上下级关系
    createAccount() {
      let _that = null;
      _that = this;

      let req = null;
      req = localStorage.getItem("DK_WXINFO") ? _that.$qs.parse(localStorage.getItem("DK_WXINFO")) : {};
      req.agentid = _that.shareid;
      req.merchid = _that.merchid;
      _that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/user/wetch_register",
          _that.$qs.stringify(
            req
          )
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            localStorage.setItem("DK_UID",res.data.data);
            localStorage.setItem("DK_ISLOGIN",true);
            localStorage.setItem("DK_STRID",res.data.user.strId);
            _that.shareid = res.data.user.id;
            _that.loadshow = true;
            _that.reloapage(); // 重新加载页面去除code参数 防止刷新报错
          }
        })
        .catch(err => {
          console.log(err);
        })
    },

    // 更新数据
    updateOpneUser(uid, openid) {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =
        "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/pay/setopenid",
          _that.$qs.stringify({
            openid: openid,
            mid: uid,
            type: "daka",
          })
        )
        .then((response) => {
          if (response.data.code == 100000) {
            // _that.reloapage(); // 重新加载页面去除code参数 防止刷新报错
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // 更新用户信息
    updatwxinfo(openid) {
      let _that = null;
      _that = this;
      console.log(openid);
      _that.$axios.defaults.headers["Content-Type"] =
        "application/x-www-form-urlencoded";
      _that.$axios.defaults.headers.common[
        "Authorization"
      ] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(
          _that.$store.state.domain + "web/wetch/get_wxsnsinfo",
          _that.$qs.stringify({
            openid: openid
          })
        )
        .then((res) => {
          console.log(res);
          if (res.data.openid == openid) {
            localStorage.setItem("DK_WXINFO", _that.$qs.stringify(res.data));
            localStorage.setItem("DK_UNIONID", res.data.unionid);
            /* 保存openID */
            _that.saveOpenid();
            if (res.data && res.data.headimgurl) {
              _that.$axios
                .post(
                  _that.$store.state.domain + "web/user/updateuserinfo",
                  _that.$qs.stringify(res.data)
                )
                .then((res) => {
                  console.log(res);
                  // _that.$router.replace({
                  //   name: "Details",
                  //   query: {
                  //     id: _that.goodsId,
                  //     shareid: _that.shareid,
                  //     merchid: _that.merchid,
                  //   },
                  // });
                  // _that.$router.go(0);
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 分享初始化
    peizhiWxConfig() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =
        "application/x-www-form-urlencoded";
      /* 获取wx初始化配置 */
      _that.$axios
        .post(
          _that.$store.state.domain + "web/wetch/wx_sign",
          _that.$qs.stringify({
            url: encodeURIComponent(window.location.href)
          })
        )
        .then(function (response) {
          _that.wxConfig.timestamp = response.data.timestamp;
          _that.wxConfig.nonceStr = response.data.nonceStr;
          _that.wxConfig.signature = response.data.signature;
          window.wx.config(_that.wxConfig); // wx初始化配置
          _that.onShare();
        })
        .catch(function (error) {
          console.log(error, "errors");
        });
    },
    /* 判断是否在微信中打开，如在微信中打开获取openid或用户信息 */
    getwxinfo() {
      let _that = null;
      _that = this;
      /* 以下下是获取openID 以及判断用户是否在微信中打开页面 */
      let openid = null;
      openid = localStorage.getItem("DK_OPENID");
      if (!window.isWeixin()) {
        openid = true; // 在H5页面中打开
        this.iswx = false;
      }

      /* 在微信中打开页面 */
      if (!openid || !this.userinfo.avatar) {
        this.iswx = true;
        let wxAuthUrl = null;
        wxAuthUrl =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc465682b705715e5&redirect_uri=" +
          encodeURIComponent(window.location.href) +
          "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
        // 传到后台得到openid, 其中access_token 2小时过期
        if (_that.code == null || _that.code === "") {
          window.location.replace(wxAuthUrl); // [自刷新当前页]
        }
      } else {
        // localStorage存在openid || 本页面不在公众号中打开
        console.log("localStorage存在openid || 本页面不在公众号中打开");
      }
    },
    /* 重新刷新页面 */
    reloapage() {
      let _that = null;
      _that = this;
      // window.location.href = "/details?id=" + _that.goodsId + "&shareid=" + _that.shareid + '&merchid=' + _that.merchid ; // 成功后重定向，需要去除code参数，防止刷新报错(localStorage['DK_OPENID']控制是否刷新code)

      const ISLOGIN = localStorage.getItem("DK_ISLOGIN");
      const OPENID = localStorage.getItem("DK_OPENID");
      /* 是否在未登录状态下点击购买按钮如果点击了跳转到确认支付页面获取进行登录或注册 */
      if ((ISLOGIN || OPENID) && this.iswx) {
        if (localStorage.getItem("buyBtn")) {
          this.showBase = false;
          localStorage.removeItem("buyBtn");
          this.$router.replace({
            name: "Details",
            query: {
              id: this.goodsId,
              shareid: this.shareid,
              merchid: this.merchid,
              _t: 3
            },
          });
          this.$router.push({
            name: "V1Payment",
            query: { shareid: this.shareid, merchid: this.merchid },
          });
        }else{
          this.$router.replace({
            name: "Details",
            query: {
              id: this.goodsId,
              shareid: this.shareid,
              merchid: this.merchid,
              _t: 3
            },
          });
        }
      }
    },

    /* 获取产品信息 */
    getgoodsInfo() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common[
        "Authorization"
      ] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =
        "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/goods/get_info",
          _that.$qs.stringify({
            id: _that.goodsId,
            merchid: _that.merchid,
          })
        )
        .then(function (response) {
          _that.loadshow = true;
          if (response && response.data) {
            // 产品信息
            if (response.data.info) {
              _that.info = response.data.info;
              _that.skuData.goods.title = _that.info.title;
              _that.skuData.goodsid = _that.goodsId;
              _that.skuData.goods.picture = _that.info.thumb;
              _that.skuData.sku.price = (_that.info.show_price * 1).toFixed(2);
              _that.buybtntext = "立即购买";
              _that.buybtnable = false;
              if (_that.info.type == "2" || _that.info.type == "3") {
                _that.AddcartAble = true;
              } else {
                _that.AddcartAble = false;
              }
              _that.content = _that.info.content ? _that.info.content : '';
            } else {
              _that.buybtntext = "已下架";
              _that.buybtnable = true;
              _that.$toast(response.data.msg ? response.data.msg : "该产品已下架或不存在");
              return false;
            }
            // 参数信息
            if (response.data.param) {
              _that.param = response.data.param;
            }
            // 规格信息
            if (response.data.spec) {
              const spec = [];
              response.data.spec.forEach((item, index) => {
                if (item.title) {
                  _that.skuTitle += item.title + "、";
                }
                const arr = {
                  k: item.title,
                  "k_id": item.id,
                  v: item.children,
                  "k_s": "s" + (index + 1),
                  largeImageMode: false,
                };
                spec.push(arr);
              });
              _that.skuData.sku.tree = spec;
              _that.skuTitle = _that.skuTitle.substring(
                0,
                _that.skuTitle.length - 1
              );
              _that.skuData.initialSku = {
                s1: response.data.spec[0].children[0].id,
                s2:
                  response.data.spec[1] && response.data.spec[1].children[0].id
                    ? response.data.spec[1].children[0].id
                    : "",
                s3:
                  response.data.spec[2] && response.data.spec[2].children[0].id
                    ? response.data.spec[2].children[0].id
                    : "",
                selectedNum: 1,
                yushouTime: "",
              };
            }
            // sku信息
            if (response.data.option) {
              const option = [];
              response.data.option.forEach((item) => {
                let specid = [];
                if (item.specs) {
                  specid = item.specs.split("_");
                }
                const arr = {
                  id: item.id,
                  price: item.price * 100, //价格
                  s1: specid[0],
                  s2: specid[1],
                  s3: specid[2],
                  "stock_num": parseInt(item.stock), //库存
                  "yushou_time": item.yushou_time,
                };
                option.push(arr);
              });
              _that.skuData.sku.list = option;
              _that.skuData.sku["collection_id"] = option[0].id;
            }
            // 用户基本信息
            if (response.data.user) {
              _that.userinfo = response.data.user;
            }
            // 商户信息
            if (response.data.merch) {
              _that.merchinfo = response.data.merch;
            }
            // 供货商信息
            if (response.data.gsh_info) {
              _that.ghsinfo = response.data.gsh_info;
            }
          }
          // 在微信中
          if (_that.iswx) {
            /* 设置分享信息 */
            _that.peizhiWxConfig(); // 配置微信分享
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    /* 加载详情 */
    loadDetail() {
      console.log(this.info.content);
      // this.content = this.info.content ? this.info.content : '';
      this.listloading = false;
      this.finished = true;
    },
    /* 设置分享信息 */
    onShare() {
      let _that = null;
      _that = this;
      let mid = 0; // 分享者id
      let title = _that.info.title; // 分享标题
      let desc = _that.info.title; // 分享简介
      let img = _that.info.thumb; // 分享图片地址
      if (_that.userinfo.id && parseInt(_that.userinfo.id) > 0) {
        mid = _that.userinfo.id;
      }
      if (_that.info.share_title && _that.info.share_title.length > 10) {
        title = _that.info.share_title;
      }
      if (_that.info.description && _that.info.description.length > 10) {
        desc = _that.info.description;
      }
      if (_that.info.share_icon && _that.info.share_icon.length > 10) {
        img = _that.info.share_icon;
      }

      window.wx.ready(function () {
        //需在用户可能点击分享按钮前就先调用
        window.wx.updateAppMessageShareData({
          title: title, // 分享标题
          desc: desc, // 分享描述
          link:
            _that.$store.state.domain3 +
            "details?id=" +
            _that.goodsId +
            "&shareid=" +
            mid +
            "&merchid=" +
            _that.merchid +
            "&_t=1", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: img, // 分享图标
          type: "link",
          success: function () {
            console.log("分享设置成功");
          },
          error: function () {
            console.log("ddd");
          },
        });
      });
    },
    /* 显示生成海报弹框 */
    onQr() {
      
      let _that = null;
      _that = this;
      let mid = null;
      mid = 0;
      if (_that.userinfo.id) {
        mid = _that.userinfo.id;
      } else {
        mid = _that.shareid;
      }
      _that.showHaibao = true;
      setTimeout(() => {
        // 未生成本产品海报
        if (!_that.tempSrc) {
          document.getElementById("haibaoshare").style.display = "block";
          document.getElementById("show").style.display = "none";
          _that.$axios
            .post(
              _that.$store.state.domain + "web/goodsposter/create",
              _that.$qs.stringify({
                "goods_id": _that.goodsId, // 商品ID：
                "user_id": _that.userinfo.id, // 会员ID：
                "shop_id": _that.merchinfo.id, // 店铺ID：
                "shop_name": _that.merchinfo.merchname, // 店铺名称：
                "shop_logo": _that.merchinfo.logo, // 店铺LOGO：
                "goods_name": _that.info.title, // 商品名称：
                "goods_img": _that.info.thumb, // 商品收徒：
                "qr_url":
                  _that.$store.state.domain3 +
                  "details?id=" +
                  _that.goodsId +
                  "&shareid=" +
                  mid +
                  "&merchid=" +
                  _that.merchid +
                  "&_t=1", // 二维码链接：
              })
            )
            .then((res) => {
              console.log(res);
              if (res.data.code == 100000) {
                _that.$toast("海报生成成功");
                _that.tempSrc = res.data.data;
                document.getElementById("haibaoshare").style.display = "none";
                document.getElementById("show").style.display = "block";
                document.getElementById("img").src = res.data.data;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }else {
          // 已生成本产品海报 直接显示
          document.getElementById("haibaoshare").style.display = "none";
          document.getElementById("show").style.display = "block";
        }
      }, 100);
          
    },
    /* 进入购物车页面 */
    onCart() {
      this.$router.push({
        name: "Cart",
        query: { showback: true, shareid: this.shareid, merchid: this.merchid, _t: 3 },
      });
    },
    goPageMerchDetail(id) {
      this.$router.push({
        name: "Ghslist",
        query: { shareid: this.shareid, merchid: this.merchid, ghsid: this.ghsinfo.id, showghs: 1 },
      });
    },
    /* 获取购物车产品数量 */
    getCartNum() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common[
        "Authorization"
      ] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =
        "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/cart/get_goodsnum",
          _that.$qs.stringify({
            merchid: _that.merchid,
          })
        )
        .then((response) => {
          if (response.data.data) {
            _that.cartNum = response.data.data;
          } else {
            _that.cartNum = 0;
          }
        })
        .catch((error) => {
          _that.cartNum = 0;
          console.log(error);
        });
    },
    /* 执行添加购物车按钮 */
    onAddCartClicked(data) {
      let _that = null;
      _that = this;
      if (!_that.info.id || _that.info.id <= 0) {
        _that.$toast("该产品已下架或不存在");
        return false;
      }
      const ISLOGIN = localStorage.getItem("DK_ISLOGIN");
      const OPENID = localStorage.getItem("DK_OPENID");

      if (!ISLOGIN) {
        _that.$notify({
          type: "warning",
          message: "未登录",
        });
        if (!_that.iswx) {
          // H5页面 跳转到登录页
          setTimeout(() => {
            _that.$router.push({
              name: "Login",
              query: { shareid: _that.shareid, merchid: _that.merchid },
            });
          }, 2000);
        }

        if ((!OPENID || !_that.userinfo.avatar) && _that.iswx) {
          _that.getwxinfo(); // 查看是否再微信中打开
        }

        return false;
      }
      _that.$axios.defaults.headers.common[
        "Authorization"
      ] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =
        "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/cart/add",
          _that.$qs.stringify({
            sku: data,
            merchid: _that.merchid,
          })
        )
        .then((response) => {
          if (response.data.code == 100000) {
            _that.showBase = false;
            _that.cartNum = parseInt(response.data.data);
            this.$toast({
              message: "添加购物车成功",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    /* 点击立即购买按钮 显示sku弹框 */
    gobuying() {
      if (!this.info.id || this.info.id <= 0) {
        this.$toast("该产品已下架或不存在");
        return false;
      }
      let wxinfo = null;
      wxinfo = localStorage.getItem("DK_WXINFO");
      if (window.isWeixin()) {
        if (this.islogin && this.userinfo && !this.userinfo.avatar && !wxinfo) {
          this.getwxinfo();
          return false;
        }
      }
      this.showBase = true; // 展示SKU弹框
    },
    /* 执行立即购买按钮 */
    onBuyClicked(data) {
      let _that = null;
      _that = this;
      if (!_that.info.id || _that.info.id <= 0) {
        _that.$toast("该产品已下架或不存在");
        return false;
      }
      const goods = [];
      const arr = {
        goodsid: data.goodsId,
        optionid: data.selectedSkuComb.id,
        num: data.selectedNum,
        "merchid_fake": _that.merchid,
      };
      goods.push(arr);
      localStorage.setItem("DK_DATA", _that.$qs.stringify(goods));
      _that.showBase = false;
      _that.$router.push({
        name: "V1Payment",
        query: { shareid: _that.shareid, merchid: _that.merchid },
      });
    },
    /* 未选择规格提示 */
    customSkuValidator() {
      return "请选择" + this.skuTitle;
    },
    /* 收藏按钮 */
    Favorite() {
      let _that = null;
      _that = this;
      if (_that.islogin && _that.userinfo) {
        _that.$axios.defaults.headers.common[
          "Authorization"
        ] = localStorage.getItem("DK_UID");
        _that.$axios.defaults.headers["Content-Type"] =
          "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/goods/favorite",
            _that.$qs.stringify({
              id: _that.goodsId,
              memberid: _that.userinfo ? _that.userinfo.id : 0,
              merchid: _that.merchid,
            })
          )
          .then((response) => {
            console.log(response);
            if (response && response.data && response.data.code == 100000) {
              if (_that.userinfo.favorite == 1) {
                _that.userinfo.favorite = 0;
              } else {
                _that.userinfo.favorite = 1;
                _that.$notify({
                  type: "success",
                  message: response.data.msg ? response.data.msg : "收藏成功",
                  duration: 1500,
                });
              }
            } else {
              _that.$notify({
                type: "warning",
                message: response.data.msg ? response.data.msg : "收藏失败",
                duration: 1500,
              });
            }
          });
      } else {
        // 未登录
        _that.$notify({
          type: "warning",
          message: "未登录，请登录后再收藏",
        });
      }
    },
    /* 跳转到首页 */
    gohome() {
      this.$router.replace({
        name: "Home",
        query: { shareid: this.shareid, merchid: this.merchid, _t: 3 },
      });
    },
    /* 返回上一页 */
    onBak() {
      if (this.frompage != "/") {
        this.$router.back();
      } else {
        this.$router.replace({
          name: "Home",
          query: { shareid: this.shareid, merchid: this.merchid, _t: 3 },
        });
      }
    },
    /* 特殊区域弹框显示/隐藏 */
    showTeshuArea() {
      console;
      if (this.teshushow) {
        this.teshushow = false;
      } else {
        this.teshushow = true;
      }
    },
    /* 参数弹框显示/隐藏 */
    showParam() {
      if (this.paramShow) {
        this.paramShow = false;
      } else {
        this.paramShow = true;
      }
    },

    // 子商户绑定会员
    setmerchMber() {
      let _that = null;
      _that = this;
      if (localStorage.getItem("DK_ISLOGIN") && this.merchid > 0) {
        _that.$axios.defaults.headers.common[
          "Authorization"
        ] = localStorage.getItem("DK_UID");
        _that.$axios.defaults.headers["Content-Type"] =
          "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/user/merch_adduser",
            _that.$qs.stringify({
              merchid: _that.merchid,
            })
          )
          .then((res) => {
            console.log(res);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    // 复制关键字
    keywordsonCopy() {
      this.$toast("复制成功");
    },
    // 复制关键字
    keywordsonError() {
      this.$toast("复制失败");
    },
    // 七鱼客服
    qiyukefu() {
      let _that = null;
      _that = this;
      if ((_that.merchid == 0 || this.showkefu == 1)) {
        let uid = 0;
        let name = "未登录—游客";
        let agentCode = "";
        let level = 1;
        let avatar = "";
        let levelname = "普通会员";
        let mobile = "";
        if (_that.islogin && _that.userinfo && _that.userinfo.id) {
          if (_that.userinfo.level == "6") {
            level = 2;
          } else if (_that.userinfo.level == "1") {
            level = 3;
          } else if (_that.userinfo.level == "14") {
            level = 4;
          } else if (_that.userinfo.level == "5") {
            level = 5;
          }
          uid = _that.userinfo.id;
          name = _that.userinfo.nickname;
          agentCode = _that.userinfo.agent_code;
          avatar = _that.userinfo.avatar;
          if (_that.userinfo.levelname) {
            levelname = _that.userinfo.levelname;
          }
          mobile = _that.userinfo.mobile;
        } else {
          _that.$toast("请先登录");
          return false;
        }

        if(_that.userinfo.id > 0) {
          let str = "";
          let desc = "";
          str = JSON.stringify([
            {"index":0, "key":"avatar","label":"头像","value":avatar},
            {"index":1, "key":"agent_code", "label":"邀请码", "value":agentCode},
            {"index":2, "key":"level", "label":"等级", "value":levelname},
            {"index":3, "key":"shopname", "label":"商户名称", "value":localStorage.getItem("DK_SHOPNAME")},
            {"index":4, "key":"mobile", "label":"手机号", "value":mobile},
            {"index":5, "key":"shopid", "label":"商户id", "value":_that.merchid},
            {"index":6, "key":"shareid", "label":"分享者id", "value":_that.shareid}
          ]);
          desc = JSON.stringify([{title: _that.info.title}, {picture: _that.info.thumb}]);
          window.location.href = "https://kefu.midbest.cn/wap/?uid=" + _that.userinfo.id + "&sessionform=" + str + "&card=" + desc + "&fromType=1";
          return false;
        }
      } else {
        this.$toast("页面还未加载完，稍等一下");
      }
    },
    // 添加浏览记录
    setprivew() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common[
        "Authorization"
      ] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =
        "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/user/liulanjilu",
          _that.$qs.stringify({
            gid: _that.goodsId,
          })
        )
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 求库存
    qiukucun() {
      console.log("qiukucun");
      let _that = null;
      _that = this;
      _that.$toast({
        type: "loading",
        message: "加载中...",
        forbidClick: true,
        duration: 0
      })
      _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/goods/ask_stock",
          _that.$qs.stringify({
            id: _that.goodsId,
            shopid: _that.merchid,
            merchid: _that.info.merchid,
            fromtype: 3
          })
        )
        .then(res => {
          console.log(res);
          _that.$toast(res.data.msg ? res.data.msg : "提交失败");
        })
        .catch(err => {
          console.log(err);
        })
    },

    /**
     * 多地址下单
     */
    moreAddresspay() {
      let _that = null;
      _that = this;
      let data = null;
      data = this.$refs.vanskudata.getSkuData();

      if (!_that.info.id || _that.info.id <= 0) {
        _that.$toast("该产品已下架或不存在");
        return false;
      }
      const goods = [];
      const arr = {
        goodsid: data.goodsId,
        optionid: data.selectedSkuComb.id,
        num: data.selectedNum,
        "merchid_fake": _that.merchid,
      };
      goods.push(arr);
      localStorage.setItem("DK_DATA", _that.$qs.stringify(goods));
      _that.showBase = false;
      _that.$router.push({
        name: "V1PaymentMore",
        query: { shareid: _that.shareid, merchid: _that.merchid },
      });
    },
  },
};
</script>
<style lang="less">
.details {
  position: relative;
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #f2f2f2;
  z-index: 2;
  // 导航
  .van-nav-bar {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background: rgba(0, 0, 0, 0);
    .van-nav-bar__left {
      padding: 10px 0 0 10px;
    }
    .van-nav-bar__right {
      padding: 10px 0 0;
    }
    .van-nav-bar__title {
      max-width: 100%;
      padding-top: 10px;
      .van-icon {
        float: right;
      }
    }
    .van-icon {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.4);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      line-height: 40px;
      text-align: center;
      font-size: 20px;
      margin-right: 16px;
    }
  }
  // 主图
  .thumbs {
    overflow: hidden;
    position: relative;
    .van-swipe {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
    .qiukucun {
      position: fixed;
      top: 70px;
      right: 16px;
      z-index: 5;
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 30px;
      height: 30px;
      line-height: 30px;
      font-size: 12px;
      color: #fff;
      padding: 0 12px;
    }
  }
  // 价格
  .price {
    .van-cell {
      text-align: left;
      padding: 10px;
      line-height: 30px;
      .van-cell__title,
      .van-cell__value {
        flex: 1;
      }
      .van-tag--danger {
        padding: 0 10px;
        font-size: 14px;
        line-height: 20px;
        height: 20px;
        background-color: #d54350;
        margin-right: 10px;
        margin-top: 5px;
      }
      .van-cell__title {
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        color: #d54350;
        strong {
          font-size: 18px;
          margin: 0 10px 0 0;
        }
        span {
          font-weight: bold;
        }
      }

      .tunhuo {
        background: #fff;
        font-size: 12px;
        border-radius: 2px;
        height: 20px;
        line-height: 20px;
        padding: 0 10px;
        margin-top: 5px;
      }
    }
  }
  // 产品标签
  .attrtag {
    text-align: left;
    padding: 0 10px 10px;
    overflow: hidden;
    background-color: #fff;
    .van-tag {
      margin-right: 10px;
      padding: 2px 10px;
      background-color: #fef4f5;
      color: #e06d5f;
      border-radius: 4px;
    }
  }
  // 产品标题
  .title {
    padding: 0 10px 10px;
    background-color: #fff;
    .van-cell {
      padding: 0;
      text-align: left;
      .van-cell__title {
        flex: 1;
        font-size: 15px;
        font-weight: bold;
        color: #333;
        line-height: 20px;
      }
      .van-cell__label {
        font-size: 13px;
        font-weight: normal;
      }
    }
  }

  // 微商相册关键词
  .weishangxiangce {
    margin-top: 10px;
    overflow: hidden;
    padding: 10px 0;
    width: 100%;
    background-color: #fff;
    .van-cell {
      padding: 0 10px;
      text-align: left;
      .van-cell__title {
        font-size: 15px;
        font-weight: bold;
        color: #333;
        line-height: 20px;
        flex: 1;
      }
      .van-cell__value {
        .van-tag {
          padding: 2px 10px;
        }
      }
      .guanjianci {
        font-weight: normal;
        font-size: 14px;
      }
      .van-cell__label {
        font-size: 13px;
        font-weight: normal;
        text-align: center;
      }
    }
  }

  .goods_part {
    margin-top: 10px;
    background-color: #fff;
    overflow: hidden;
    padding: 0 10px 10px;
    // 会员、价格 部分
    .huiyuan {
      .van-cell {
        padding: 10px 0 0;
        .van-cell__title {
          min-width: 40px;
          padding-right: 10px;
          text-align: left;
          font-weight: bold;
        }
        .van-cell__value {
          flex: 1;
          text-align: left;
          .van-tag {
            font-size: 12px;
            margin-right: 10px;
            margin-bottom: 10px;
            margin-top: 1px;
          }
        }
      }
      .show_price {
        .van-cell__value {
          .van-tag {
            font-size: 12px;
            margin-right: 5px;
            margin-bottom: 10px;
            margin-top: 1px;
          }
        }
      }
    }
    // 发货、特殊区域
    .postage {
      .van-cell {
        padding: 10px 0 0;
        .van-cell__title {
          min-width: 56px;
          padding-right: 10px;
          text-align: left;
          font-weight: bold;
          color: #999;
        }
        .van-cell__value {
          flex: 1;
          text-align: left;
          color: #333;
          font-weight: bold;
          .address {
            margin-right: 10px;
            padding: 0 10px 0 0;
            vertical-align: middle;
            line-height: 10px;
            height: 10px;
            font-size: 14px;
            color: #333;
            .van-icon {
              font-size: 14px;
              line-height: 10px;
              color: #333;
            }
            border-radius: 0;
            &::before {
              border-width: 0 1px 0 0;
            }
          }
          .address.van-hairline--surround::after {
            border-top-width: 0;
            border-bottom-width: 0;
            border-left-width: 0;
            // border-right-width: 0;
            border-radius: 0;
          }
          .express {
            padding: 0;
            vertical-align: middle;
            line-height: 10px;
            height: 10px;
            font-size: 14px;
            color: #333;
            &::before {
              border-width: 0;
            }
          }
        }
      }
      .teshuquyu {
        .van-cell__value {
          .van-tag {
            margin-right: 5px;
          }
        }
        .van-icon {
          line-height: 24px;
          color: #333;
        }
      }
    }
    // 规格、参数
    .spec {
      .van-cell {
        padding: 10px 0 0;
        .van-cell__title {
          min-width: 40px;
          padding-right: 10px;
          text-align: left;
          font-weight: bold;
          color: #999;
        }
        .van-cell__value {
          flex: 1;
          text-align: left;
          color: #333;
          font-weight: bold;
          .address {
            margin-right: 10px;
            padding: 0 10px 0 0;
            vertical-align: middle;
            line-height: 10px;
            height: 10px;
            font-size: 14px;
            color: #333;
            .van-icon {
              font-size: 14px;
              line-height: 10px;
              color: #333;
            }
          }
          .address.van-hairline--surround::after {
            border-top-width: 0;
            border-bottom-width: 0;
            border-left-width: 0;
            border-radius: 0;
          }
          .express {
            padding: 0;
            vertical-align: middle;
            line-height: 10px;
            height: 10px;
            font-size: 14px;
            color: #333;
          }
          .express.van-hairline--surround::after {
            border-width: 0;
          }
        }
      }
      .teshuquyu {
        .van-cell__value {
          .van-tag {
            margin-right: 5px;
          }
        }
        .van-icon {
          line-height: 24px;
          color: #333;
        }
      }
    }
  }

  // 进店逛逛
  .ghsinfo {
    padding: 10px 10px;
    background-color: #fff;
    .van-cell {
      padding: 0;
      text-align: left;
      .van-cell__title {
        flex: 1;
        font-size: 15px;
        font-weight: bold;
        color: #333;
        line-height: 20px;
        padding-left: 10px;
      }
      .van-cell__label {
        font-size: 13px;
        font-weight: normal;
      }
    }
  }
  // 产品详情
  .goods_content {
    padding: 10px 10px 50px;
    overflow: hidden;
    background-color: #fff;
    margin-top: 10px;
    .van-cell {
      padding: 0;
      color: #999;
      text-align: left;
      font-weight: bold;
      padding-bottom: 10px;
    }
    p {
      margin: 0;
      img:not(.kindeditor-ku-img) {
        width: 100%;
        display: block;
      }
    }
    img:not(.kindeditor-ku-img) {
      width: 100%;
      display: block;
    }
  }
  // 底部按钮
  .van-popup--bottom.van-popup--round {
    text-align: left;
  }
  .van-goods-action {
    .favorited {
      .van-icon {
        color: #e06d5f;
      }
    }
  }
  // 特殊区域弹框
  .popup_teshuarea {
    .area_title {
      padding: 16px;
      .van-cell__title {
        flex: 1;
        text-align: center;
      }
    }
    .van-cell-group {
      overflow-y: auto;
      overflow-x: hidden;
      .area_list_title {
        padding: 0 16px 10px;
      }
      .area_list_content {
        padding: 0 16px 10px;
        font-size: 12px;
        .van-cell__title {
          padding-right: 10px;
        }
        .van-cell__value {
          flex: 1;
          text-align: left;
          color: #999;
        }
      }
    }
    .popup_button {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background: crimson;
      color: #fff;
      width: 100%;
      height: 40px;
      line-height: 40px;
    }
  }
  // 参数弹框
  .popup_param {
    overflow: hidden;
    .param_title {
      padding: 16px;
      text-align: center;
      .van-cell__title {
        flex: 1;
      }
    }
    .van-cell-group {
      overflow-x: hidden;
      overflow-y: auto;
      .list_title {
        min-width: 60px;
        padding-right: 10px;
        text-align: left;
      }
      .list_value {
        flex: 1;
        text-align: left;
      }
    }
    .param_button {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background: crimson;
      color: #fff;
      width: 100%;
      height: 40px;
      line-height: 40px;
    }
  }
  // 加载中
  #loading {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    .van-loading {
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -30px 0 0 -30px;
      color: #fff;
      .van-loading__text {
        color: #fff;
      }
    }
  }
  // 分享弹框
  .haibao_main {
    overflow-y: initial;
    width: 80%;
    position: fixed;
    top: 20px;
    left: 0;
    margin: 10%;
    transform: none;
    .van-popup__close-icon--top-right {
      top: -30px;
      right: 0;
    }
    .haibaoshare {
      padding: 10px;
      .share_merch {
        margin-bottom: 10px;
        .van-cell {
          padding: 0;
          .van-cell__title {
            .van-image {
              display: block;
            }
          }
          .van-cell__value {
            color: #333;
            padding-left: 10px;
            text-align: left;
            flex: 1;
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
      .share_img {
        .van-image {
          width: 100%;
        }
      }
      .share_code_title {
        padding-top: 10px;
        .van-cell {
          padding: 0;
          .van-cell__title {
            height: 100px;
            width: 100px;
            .qrcode {
              width: 100px;
              height: 100px;
              background-image: #fff;
            }
          }
          .van-cell__value {
            flex: 1;
            padding-left: 10px;
            color: #333;
            text-align: left;
            line-height: 20px;
          }
        }
      }
      .titshi {
        width: 100%;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        color: #999;
        padding-top: 10px;
      }
    }
  }
  .van-sku-header {
    .van-sku-header__img-wrap {
      min-width: 96px;
    }
  }
  .red {
    color: crimson;
  }
  // 水印
  .shuiyin {
    padding: 2px;
    background-color: #fff;
    position: absolute;
    right: 0;
    bottom: 0;
    color: #808080;
    opacity: 0.5;
    font-size: 14px;
    line-height: 16px;
  }
  .van-sku__price-tag {
    background-color: #9168ed;
    color: #fff;
    border-radius: 4px;
    height: 16px;
  }

  // 填写邀请码
  .agent_code {
    padding: 20px;
    overflow: hidden;
    background-color: #fff;
    .van-cell {
      margin-bottom: 10px;
      .van-cell__value {
        flex: 1;
      }
    }
  }
}
</style>
